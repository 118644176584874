import i1 from "../../images/shop/Stockroom/1_euroSummerOriginal.jpg";
import i2 from "../../images/shop/Stockroom/2_euroSummerOriginal.jpg";
import i3 from "../../images/shop/Stockroom/3_euroSummerOriginal.JPG";
import i4 from "../../images/shop/Stockroom/4_euroSummerOriginal.JPG";
import i5 from "../../images/shop/Stockroom/5_euroSummerOriginal.JPG";


const euroSummerOriginalCollection = [
  { image: i1, alt: "Euro Summer" },
  { image: i2, alt: "Euro Summer" },
  { image: i3, alt: "Euro Summer" },
  { image: i4, alt: "Euro Summer" },
  { image: i5, alt: "Euro Summer" },
  ];

export default euroSummerOriginalCollection;
