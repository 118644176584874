import React, { useEffect } from "react";
import imageScroller from "./../../../utils/imageScroller";
import HeaderSpacer from "./../../../components/HeaderSpacer";
import euroSummerOriginalCollection from "./../../../data/shop/euroSummerOriginal_Data";
import BackButton from "./../../../components/BackButton";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
// import renderSoldLabel from "./../../../components/SoldText";
import SlideShow from "./../../../components/SlideShow";
import { Helmet } from "react-helmet-async";



function EuroSummerOriginal() {
  useEffect(() => {
    imageScroller("App");
  });

  return (
    <div>
      <Helmet>
        <title>TL | Euro Summer</title>
        <meta property='og.title' content='Euro Summer- 2024' />
        <meta property='og.image' content={euroSummerOriginalCollection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        <strong>Euro Summer- 2024 </strong>
        
      </p>
      <p>Oil & acrylic on linen</p>
      <p>51cm x 61cm (20" x 24")</p>
      <p>Stretched linen on victorian ash wood stretcher bars ready to hang.</p>
      <p>
        <strong>$2,650AUD</strong>
      </p>
      <p>Free international shipping</p>
      <br></br>
      
      <SlideShow
        slides={euroSummerOriginalCollection}
        slideShowName='euroSummerOriginalSlides'
        startingIndex={0}
        scrollOffset={-20}
      />

      <Square
        thePrice={"$2,650.00"}
        theTitle={"Euro Summer - Oil & acrylic on linen"}
        theLink={"https://square.link/u/6vAVVVOl?src=embed"}
      />
      <SquareSpacer />
      
      <BackButton />
    </div>
  );
}

export default EuroSummerOriginal;
