import i1 from "../../images/shop/27thprint_euroSummer/1_euroSummer.jpg";
import i2 from "../../images/shop/27thprint_euroSummer/2_euroSummer.jpg";
import i3 from "../../images/shop/27thprint_euroSummer/3_euroSummer.jpg";
import i4 from "../../images/shop/27thprint_euroSummer/4_euroSummer.jpg";
import i5 from "../../images/shop/27thprint_euroSummer/5_euroSummer.jpg";
import i6 from "../../images/shop/27thprint_euroSummer/6_euroSummer.jpg";


const euroSummerCollection = [
  { image: i1, alt: "Euro Summer" },
  { image: i2, alt: "Euro Summer" },
  { image: i3, alt: "Euro Summer" },
  { image: i4, alt: "Euro Summer" },
  { image: i5, alt: "Euro Summer" },
  { image: i6, alt: "Euro Summer" },
  
];

export default euroSummerCollection;
